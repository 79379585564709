<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="anasalebranch">
    <div class="anasalebranch_box">
      <!-- <p class="box2_tit">
                <span @click="dianji_fanhui">客户管理</span>
                <i class="el-icon-arrow-right"></i>
                <span>分配规则</span>
            </p> -->
      <p class="box4_title">客户分配规则</p>
      <ul class="box4_tit">
        <li class="li">
          <div class="li_hang">
            <span>公海池</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.gonghai_chi"
                :key="index"
                @click="fenpei.gonghai_chi_num = index"
              >
                <img
                  :src="
                    fenpei.gonghai_chi_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          基础数量
          <input class="kuang" type="number" v-model="fenpei.jichu_num" />
        </li>

        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <div class="li_hang">
            <span>客户分配比例</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.kehu_fenpei"
                :key="index"
                @click="fenpei.kehu_fenpei_num = index"
              >
                <img
                  :src="
                    fenpei.kehu_fenpei_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
          <p @click="chongxin_huoqu" class="shuaxin">
            <i :class="huoqu ? 'el-icon-loading' : ''"></i
            >{{ huoqu ? "" : "重新获取" }}
          </p>
          <p @click="zengjia_yilie" class="zengjia_yilie">增加一列</p>
          <p @click="jianshao_yilie" class="jianshao_yilie">减少一列</p>
          <p></p>
          <el-table
            :data="fenpei.kehu_fenpei_neirong"
            border
            :cell-style="liebiao"
            :header-cell-style="biaotou"
            style="width: 100%; margin-top: 0.43rem"
          >
            <el-table-column prop="dengji" label="客户等级"> </el-table-column>
            <el-table-column
              v-for="(i, index) in fenpei.kehu_fenpei_bili"
              :key="index"
              :prop="i.con"
              :label="i.name"
              min-width="110"
            >
              <template slot-scope="props">
                <p
                  v-if="
                    i.con != 'dengji' &&
                    i.con == 'qujian1' &&
                    props.row.dengji == '员工本年目标销售额区间'
                  "
                >
                  <span class="liebiao_text_yiban"
                    >0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;—
                  </span>
                  <input
                    class="liebiao_input_yiban"
                    type="number"
                    v-model="props.row[i.con]"
                    @blur="bili_qujian_num_dayu(0, props.row, i.con)"
                  />
                </p>
                <p
                  v-if="
                    i.con != 'dengji' &&
                    i.con != 'qujian1' &&
                    props.row.dengji == '员工本年目标销售额区间'
                  "
                >
                  <span class="liebiao_text_yiban"
                    >{{
                      Number(props.row["qujian" + index])
                    }}&nbsp;&nbsp;&nbsp;&nbsp;—
                  </span>
                  <input
                    class="liebiao_input_yiban"
                    type="number"
                    @blur="
                      bili_qujian_num_dayu(
                        Number(props.row['qujian' + index]),
                        props.row,
                        i.con
                      )
                    "
                    v-model="props.row[i.con]"
                  />
                </p>
                <p
                  v-if="
                    props.row.dengji != '员工本年目标销售额区间' &&
                    props.row.dengji != '合计'
                  "
                >
                  <input
                    class="liebiao_input"
                    type="number"
                    v-model="props.row[i.con]"
                  /><span class="baifen_hao">%</span>
                </p>
                <p v-if="i.con == 'dengji' && props.row.dengji != '合计'">
                  {{ props.row[i.con] }}
                </p>
                <p v-if="props.row.dengji == '合计'" class="liebiao_text">
                  {{ props.row[i.con] }}<span class="baifen_hao">%</span>
                </p>
              </template>
            </el-table-column>
          </el-table>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <span>自动分配规则</span>
          <p style="margin: 0.56rem 0 0.28rem 0">分配顺位</p>
          <el-select
            class="fenpei_shunwei"
            v-model="fenpei.fenpei_shunwei1"
            placeholder="请选择"
          >
            <el-option
              v-for="item in shunwei_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            class="fenpei_shunwei"
            v-model="fenpei.fenpei_shunwei2"
            placeholder="请选择"
          >
            <el-option
              v-for="item in shunwei_list2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            class="fenpei_shunwei"
            v-model="fenpei.fenpei_shunwei3"
            placeholder="请选择"
          >
            <el-option
              v-for="item in shunwei_list3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <br />
          <span style="margin-bottom: 0.15rem; padding-top: 0.42rem"
            >特别约定</span
          >
          <div class="tebie_yueding">
            <span>成交客户是否优先原负责人</span>
            <ul>
              <li
                v-for="(i, index) in shi_fou"
                @click="fenpei.yueding_1 = index"
                :key="index"
              >
                <img
                  :src="
                    fenpei.yueding_1 == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                />{{ i }}
              </li>
            </ul>
          </div>
          <div class="tebie_yueding">
            <span>沉淀客户是否优先分配</span>
            <ul>
              <li
                v-for="(i, index) in shi_fou"
                @click="fenpei.yueding_2 = index"
                :key="index"
              >
                <img
                  :src="
                    fenpei.yueding_2 == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                />{{ i }}
              </li>
            </ul>
          </div>
          <div class="tebie_yueding">
            <span>退回客户是否避让原负责人</span>
            <ul>
              <li
                v-for="(i, index) in shi_fou"
                @click="fenpei.yueding_3 = index"
                :key="index"
              >
                <img
                  :src="
                    fenpei.yueding_3 == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                />{{ i }}
              </li>
            </ul>
          </div>
          <div class="tebie_yueding">
            <span>公海池客户数量不足是否分配冷冻仓客户</span>
            <ul>
              <li
                v-for="(i, index) in shi_fou"
                @click="fenpei.yueding_4 = index"
                :key="index"
              >
                <img
                  :src="
                    fenpei.yueding_4 == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                />{{ i }}
              </li>
            </ul>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <div class="li_hang">
            <span>特殊指派</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.teshu_zhipai"
                :key="index"
                @click="fenpei.teshu_zhipai_num = index"
              >
                <img
                  :src="
                    fenpei.teshu_zhipai_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <div class="li_hang">
            <span>归档后客户补充</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.buchong"
                :key="index"
                @click="fenpei.buchong_num = index"
              >
                <img
                  :src="
                    fenpei.buchong_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <div class="li_hang">
            <span>奖励数量</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.teshu_zhipai"
                :key="index"
                @click="fenpei.jiangli_shuliang_num = index"
              >
                <img
                  :src="
                    fenpei.jiangli_shuliang_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
          <ul class="hetong_fapiao_huikuan">
            <li
              v-for="(i, index) in fenpei.jiangli"
              :key="index"
              @click="fenpei.jiangli_num = index"
            >
              <img
                :src="
                  fenpei.jiangli_num == index
                    ? require('../../assets/red_quan.png')
                    : require('../../assets/ccc_quan.png')
                "
                alt=""
              />
              {{ i }}
            </li>
          </ul>
          <el-table
            :data="fenpei.jiangli_tianxie"
            border
            :cell-style="liebiao"
            :header-cell-style="biaotou"
            style="width: 100%; margin-top: 0.43rem"
          >
            <el-table-column
              v-for="(i, index) in fenpei.jiangli_tit"
              :key="index"
              :prop="i.con"
              :label="i.name"
            >
              <template slot-scope="props">
                <p
                  v-if="
                    (i.con == 'wancheng_lv1' ||
                      i.con == 'wancheng_lv2' ||
                      i.con == 'shuliang') &&
                    props.row.fangan != '增加一行' &&
                    props.row.fangan == '方案一'
                  "
                >
                  <input
                    @keyup="
                      i.con == 'shuliang'
                        ? (props.row[i.con] = props.row[i.con].replace(
                            /\D/g,
                            ''
                          ))
                        : ''
                    "
                    @afterpaste="
                      i.con == 'shuliang'
                        ? (props.row[i.con] = props.row[i.con].replace(
                            /\D/g,
                            ''
                          ))
                        : ''
                    "
                    class="liebiao_input"
                    @blur="tiaozheng2(props.row)"
                    type="number"
                    v-model="props.row[i.con]"
                  /><span v-if="i.con != 'shuliang'" class="baifen_hao">%</span>
                </p>
                <p
                  v-if="
                    (i.con == 'wancheng_lv2' || i.con == 'shuliang') &&
                    props.row.fangan != '增加一行' &&
                    props.row.fangan != '方案一'
                  "
                >
                  <input
                    @keyup="
                      i.con == 'shuliang'
                        ? (props.row[i.con] = props.row[i.con].replace(
                            /\D/g,
                            ''
                          ))
                        : ''
                    "
                    @afterpaste="
                      i.con == 'shuliang'
                        ? (props.row[i.con] = props.row[i.con].replace(
                            /\D/g,
                            ''
                          ))
                        : ''
                    "
                    class="liebiao_input"
                    type="number"
                    @blur="tiaozheng2(props.row)"
                    v-model="props.row[i.con]"
                  /><span v-if="i.con != 'shuliang'" class="baifen_hao">%</span>
                </p>
                <p
                  v-if="
                    i.con != 'wancheng_lv1' &&
                    i.con != 'wancheng_lv2' &&
                    i.con != 'shuliang' &&
                    props.row.fangan != '增加一行'
                  "
                >
                  {{ props.row[i.con] }}
                </p>
                <p
                  v-if="i.con == 'fangan' && props.row.fangan == '增加一行'"
                  class="zengjia_yihang"
                  @click="zengjia_yihang2"
                >
                  {{ props.row[i.con] }}
                </p>
                <p
                  v-if="i.con == 'wancheng_lv1' && props.row.fangan != '方案一'"
                  class="liebiao_text"
                >
                  {{ props.row[i.con]
                  }}<span
                    v-if="props.row.fangan != '增加一行'"
                    class="baifen_hao"
                    >%</span
                  >
                </p>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </ul>
      <p class="box4_tuihui_tit" v-if="fenpei.gonghai_chi_num == 0">
        客户退回规则
      </p>
      <ul class="box4_tit">
        <li class="li">
          <span>自动退回规则</span>
          <div class="tebie_yueding">
            <p>
              <input
                class="kuang"
                onkeyup="this.value=this.value.replace(/\D/g,'')"
                onafterpaste="this.value=this.value.replace(/\D/g,'')"
                style="margin-right: 0.04rem"
                type="number"
                v-model="fenpei.tuihui_yue"
              />天未联系自动退回
            </p>
            <ul>
              <li
                v-for="(i, index) in fenpei.tuihui_list"
                :key="index"
                @click="fenpei.tuihui_yue_num = index"
              >
                <img
                  :src="
                    fenpei.tuihui_yue_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
          </div>
          <div class="tebie_yueding">
            <p>
              <input
                class="kuang"
                onkeyup="this.value=this.value.replace(/\D/g,'')"
                onafterpaste="this.value=this.value.replace(/\D/g,'')"
                style="margin-right: 0.04rem"
                type="number"
                v-model="fenpei.tuihui_no"
              />次拜访未成交自动退回
            </p>
            <ul>
              <li
                v-for="(i, index) in fenpei.tuihui_no_list"
                :key="index"
                @click="fenpei.tuihui_no_num = index"
              >
                <img
                  :src="
                    fenpei.tuihui_no_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <span>免职/转岗/离职退回规则</span>
          <div class="li_hang">
            <span>自动退回</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.tuihui_zidong"
                :key="index"
                @click="fenpei.tuihui_zidong_num = index"
              >
                <img
                  :src="
                    fenpei.tuihui_zidong_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
        </li>
        <li class="li" v-if="fenpei.gonghai_chi_num == 0">
          <div class="li_hang">
            <span>主动退回</span>
            <ul>
              <li
                v-for="(i, index) in fenpei.tuihui_zhudong"
                :key="index"
                @click="fenpei.tuihui_zhudong_num = index"
              >
                <img
                  :src="
                    fenpei.tuihui_zhudong_num == index
                      ? require('../../assets/red_quan.png')
                      : require('../../assets/ccc_quan.png')
                  "
                  alt=""
                />
                {{ i }}
              </li>
            </ul>
            <span></span>
          </div>
          <p>
            累计主动退回
            <input
              type="number"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
              class="kuang"
              v-model="fenpei.zhudong_tuihui_jia"
            />次,减持总客户数量
            <input
              class="kuang"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
              type="number"
              v-model="fenpei.jianchi_kehu_shuliang"
            />
          </p>
        </li>
        <li class="li">
          <span>冷冻仓规则</span>
          <p>
            公海池停留时长
            <input
              type="number"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
              class="kuang"
              v-model="fenpei.tingliu_shichang"
            />天以上自动进入冷冻仓
          </p>
          <p>
            客户被主动退回
            <input
              type="number"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
              class="kuang"
              v-model="fenpei.bei_tuihui_cishu"
            />次自动进入冷冻仓
          </p>
          <div class="lengdong_guize">
            <div>
              <p>停留时长</p>
              <p>
                <input
                  type="number"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  class="kuang"
                  v-model="fenpei.yuefen1"
                />天 ——
                <input
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  type="number"
                  class="kuang"
                  v-model="fenpei.yuefen2"
                />天
              </p>
              <p>
                <input
                  type="number"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  class="kuang"
                  v-model="fenpei.yuefen3"
                />天 ——
                <input
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  type="number"
                  class="kuang"
                  v-model="fenpei.yuefen4"
                />天
              </p>
              <p>
                <input
                  type="number"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  class="kuang"
                  v-model="fenpei.yuefen5"
                />天以上
              </p>
            </div>
            <div>
              <p>颜色标识</p>
              <p><span></span></p>
              <p><span></span></p>
              <p><span></span></p>
            </div>
          </div>
        </li>
      </ul>
      <p class="btn_sure" @click="dianji_baocun">保存</p>
    </div>
  </div>
</template>

<script>
import {
  set_customer_allot_rule,
  query_cust_level_list,
  query_customer_allot_rule_detail
} from '../../api/api.js'
export default {
  name: 'anasalebranch',
  data () {
    return {
      fenpei: {
        gonghai_chi: ['不可视', '可视'],
        gonghai_chi_num: '0',
        jichu_num: '30',
        tiaozheng_num: ['设定', '不设定'],
        tiaozheng_shuliang_num: '0',
        tiaozheng_tit: [
          {
            name: '方案',
            con: 'fangan'
          },
          {
            name: '完成率',
            con: 'shuzi'
          },
          {
            name: '至',
            con: 'gang'
          },
          {
            name: '完成率',
            con: 'shuzi2'
          },
          {
            name: '调整数量',
            con: 'shuliang'
          },
          {
            name: '持有客户数量',
            con: 'kehu_liang'
          }
        ],
        tiaozheng_tianxie: [
          {
            fangan: '方案一',
            fangan_num: 1,
            shuzi: '',
            gang: '——',
            shuzi2: '',
            shuliang: '',
            kehu_liang: ''
          },
          {
            fangan: '增加一行'
          }
        ],
        kehu_fenpei_bili: [
          {
            name: '区间一',
            con: 'qujian1'
          },
          {
            name: '区间二',
            con: 'qujian2'
          },
          {
            name: '区间三',
            con: 'qujian3'
          },
          {
            name: '区间四',
            con: 'qujian4'
          }
        ],
        kehu_fenpei_neirong: [
          {
            dengji: '员工本年目标销售额区间',
            qujian1: '',
            qujian2: '',
            qujian3: '',
            qujian4: ''
          },
          {
            dengji: '一级',
            qujian1: '',
            qujian2: '',
            qujian3: '',
            qujian4: ''
          },
          {
            dengji: '二级',
            qujian1: '',
            qujian2: '',
            qujian3: '',
            qujian4: ''
          },
          {
            dengji: '合计',
            qujian1: '',
            qujian2: '',
            qujian3: '',
            qujian4: ''
          }
        ],
        kehu_fenpei: ['设定', '不设定'],
        kehu_fenpei_num: '0',
        teshu_zhipai: ['启用', '停用'],
        teshu_zhipai_num: '0',
        buchong: ['归档客户自动退回公海池', '归档客户不退回公海池'],
        buchong_num: '0',
        jiangli: ['合同', '发票', '回款'],
        jiangli_shuliang_num: '0',
        jiangli_num: '0',
        tuihui_list: ['启用', '停用'],
        tuihui_yue: '3',
        tuihui_yue_num: '0',
        tuihui_no_list: ['启用', '停用'],
        tuihui_no: '15',
        tuihui_no_num: '0',
        liangci_num: '0',
        tuihui_zidong: ['公海池', '部门主管'],
        tuihui_zidong_num: '0',
        tuihui_zhudong: ['启用', '停用'],
        tuihui_zhudong_num: '0',
        zhudong_tuihui_jia: '10',
        jianchi_kehu_shuliang: '4',
        fenpei_shunwei1: '1',
        fenpei_shunwei2: '2',
        fenpei_shunwei3: '3',
        yueding_1: 0,
        yueding_2: 0,
        yueding_3: 0,
        yueding_4: 0,
        jiangli_tit: [
          {
            name: '方案',
            con: 'fangan'
          },
          {
            name: '完成率',
            con: 'wancheng_lv1'
          },
          {
            name: '至',
            con: 'zhi'
          },
          {
            name: '完成率',
            con: 'wancheng_lv2'
          },
          {
            name: '奖励数量',
            con: 'shuliang'
          }
        ],
        jiangli_tianxie: [
          {
            fangan: '方案一',
            fangan_num: 1,
            wancheng_lv1: '',
            zhi: '——',
            wancheng_lv2: '',
            shuliang: ''
          },
          {
            fangan: '增加一行'
          }
        ],
        liaci_tian_tuihui: '15',
        tingliu_shichang: '6',
        bei_tuihui_cishu: '7',
        yuefen1: '',
        yuefen2: '',
        yuefen3: '',
        yuefen4: '',
        yuefen5: ''
      },
      shunwei_list: [
        {
          value: '1',
          label: '成交客户'
        },
        {
          value: '2',
          label: '未分配客户'
        },
        {
          value: '3',
          label: '退回客户'
        }
      ],
      shunwei_list2: [
        {
          value: '2',
          label: '未分配客户'
        },
        {
          value: '3',
          label: '退回客户'
        }
      ],
      shunwei_list3: [
        {
          value: '3',
          label: '退回客户'
        }
      ],
      shi_fou: ['是', '否'],
      huoqu: false,
      init_qty_orig: '0'
    }
  },
  mounted () {},
  created () {
    this.jichu()
  },
  watch: {
    fenpei: {
      handler (newValue, oldValue) {
        for (let i = 1; i < newValue.tiaozheng_tianxie.length - 1; i++) {
          newValue.tiaozheng_tianxie[i].shuzi =
            Number(newValue.tiaozheng_tianxie[i - 1].shuzi2) + 1
        }
        for (let i = 1; i < newValue.jiangli_tianxie.length - 1; i++) {
          newValue.jiangli_tianxie[i].wancheng_lv1 =
            Number(newValue.jiangli_tianxie[i - 1].wancheng_lv2) + 1
        }
        this.kehu_liang_zhi()
        this.kehu_bili_heji()
        this.zidong_fenpei_guize()
      },
      deep: true
    }
  },
  methods: {
    jichu () {
      query_cust_level_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          const list = JSON.parse(res.data.body.data)
          console.log(list)
          const i = this.fenpei.kehu_fenpei_neirong
          i.splice(1, i.length)
          list.map((item, index) => {
            i.push({
              dengji: item.text,
              qujian1: '',
              qujian2: '',
              qujian3: '',
              qujian4: ''
            })
          })
          i.push({
            dengji: '合计',
            qujian1: '',
            qujian2: '',
            qujian3: '',
            qujian4: ''
          })
        } else if (res.data.code == 500) {
        }
      })
      query_customer_allot_rule_detail({
        data: {
          ent_id: this.$ent_id()
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body != null) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const i = this.fenpei
            i.gonghai_chi_num = date.flag_visible // 公海池是否可视
            i.jichu_num = date.init_qty != undefined ? date.init_qty : '' // 基础数量
            this.init_qty_orig =
              date.init_qty != undefined ? date.init_qty : '0'

            i.kehu_fenpei_num = date.flag_cust_allot_rate == 1 ? '0' : '1' // 客户分配比例  1 0
            const shunwei = date.allot_sort.split(',')
            i.fenpei_shunwei1 = shunwei[0]
            i.fenpei_shunwei2 = shunwei[1]
            i.fenpei_shunwei3 = shunwei[2]
            i.yueding_1 = date.spec_rule_1 == 1 ? '0' : '1' // 特别约定    1  0
            i.yueding_2 = date.spec_rule_2 == 1 ? '0' : '1' // 1 0
            i.yueding_3 = date.spec_rule_3 == 1 ? '0' : '1' // 1 0
            i.yueding_4 = date.spec_rule_4 == 1 ? '0' : '1' // 1 0
            i.teshu_zhipai_num = date.flag_spec_allot == 1 ? '0' : '1' // 特殊指派  1 0
            i.buchong_num = date.supply_type == 1 ? '0' : '1' // 成交后客户补充方式  1    2
            i.jiangli_shuliang_num = date.flag_reward_qty == 1 ? '0' : '1' // 奖励数量   1 0
            i.jiangli_num = Number(date.reward_qty_type) - 1 + ''; // 奖励参考的指标类型  1  2 3
            (i.tuihui_yue =
              date.auto_back_rule_1 != undefined ? date.auto_back_rule_1 : ''), // 自动退回规则
            (i.tuihui_yue_num = date.flag_auto_back_rule_1 == 1 ? '0' : '1'); // 1   0
            (i.tuihui_no =
              date.auto_back_rule_2 != undefined ? date.auto_back_rule_2 : ''), // 自动退回规则2
            (i.tuihui_no_num = date.flag_auto_back_rule_2 == 1 ? '0' : '1') // 1 0
            i.liangci_num = date.flag_auto_back_rule_3 == 1 ? '0' : '1' // 1 0
            i.liaci_tian_tuihui = date.auto_back_rule_3 // 两次拜访间隔时间超过x天自动退回
            i.tuihui_zidong_num = Number(date.auto_back_area) - 1 + '' // 1公海池  2部门主管
            i.tuihui_zhudong_num = date.manual_back_flag == 1 ? '0' : '1' // 主动退回  1启用  0不启用
            i.zhudong_tuihui_jia =
              date.manual_back_cust_num != undefined
                ? date.manual_back_cust_num
                : '' // 累计主动退回客户家数
            i.jianchi_kehu_shuliang =
              date.manual_back_subtract_qty != undefined
                ? date.manual_back_subtract_qty
                : '' // 减持总客户数量
            i.tingliu_shichang = date.cold_rule_1_month // 公海池停留时长    冷冻仓
            i.bei_tuihui_cishu = date.cold_rule_2_num // 客户被主动退回   冷冻仓
            const lv = date.stay_time_green.split(',')
            i.yuefen1 = lv[0]
            i.yuefen2 = lv[1]
            const yellow = date.stay_time_yellow.split(',')
            i.yuefen3 = yellow[0]
            i.yuefen4 = yellow[1]
            i.yuefen5 = date.stay_time_red
            if (date.list_reward.length != 0) {
              i.jiangli_tianxie.splice(0, i.jiangli_tianxie.length)
              date.list_reward.map((item, index) => {
                i.jiangli_tianxie.push({
                  fangan: item.name,
                  fangan_num: item.id2,
                  wancheng_lv1: item.rate_start,
                  zhi: '——',
                  wancheng_lv2: item.rate_end,
                  shuliang: item.qty_reward
                })
              })
              i.jiangli_tianxie.push({
                fangan: '增加一行'
              })
            }
            if (date.list_update.length != 0) {
              i.tiaozheng_tianxie.splice(0, i.tiaozheng_tianxie.length)
              date.list_update.map((item, index) => {
                i.tiaozheng_tianxie.push({
                  fangan: item.name,
                  fangan_num: item.id2,
                  shuzi: item.rate_start,
                  gang: '——',
                  shuzi2: item.rate_end,
                  shuliang: item.qty_update,
                  kehu_liang: item.qty_final
                })
              })
              i.tiaozheng_tianxie.push({
                fangan: '增加一行'
              })
            }
            console.log(date)
            if (date.cust_allot_rate_str.length != 0) {
              i.kehu_fenpei_bili = JSON.parse(
                date.cust_allot_rate_str
              ).kehu_fenpei_bili
              i.kehu_fenpei_neirong = JSON.parse(
                date.cust_allot_rate_str
              ).kehu_fenpei_neirong
            }
            // if(date.list_rate.length!=0){
            //     console.log(this.duqu_qujian(date))
            //     // for(let a=1;a>1;a++){
            //     //     if(date.list_rate[0]['level'+a]==undefined){
            //     //         return a
            //     //         break
            //     //     }
            //     // }
            // }
          }
        } else if (res.data.code == 500) {
          alert('报错了')
        }
      })
    },
    // dianji_fanhui(){
    //     this.$router.push('/cusman')
    // },
    shuzi_daxie (num) {
      if (num == 1) {
        return '一'
      } else if (num == 2) {
        return '二'
      } else if (num == 3) {
        return '三'
      } else if (num == 4) {
        return '四'
      } else if (num == 5) {
        return '五'
      } else if (num == 6) {
        return '六'
      } else if (num == 7) {
        return '七'
      } else if (num == 8) {
        return '八'
      } else if (num == 9) {
        return '九'
      } else if (num == 10) {
        return '十'
      } else if (num > 10 && num < 100) {
        console.log(num)
        return this.shuzi_daxie_shiwei(num)
      }
    },
    shuzi_daxie_shiwei (i) {
      i = i + ''
      const a = i.substring(0, 1)
      const b = i.substring(1, 2)
      let c = ''
      if (a == 1) {
        c = '十' + this.shuzi_daxie(b)
      } else {
        if (b == 0) {
          c = this.shuzi_daxie(a) + '十'
        } else {
          c = this.shuzi_daxie(a) + '十' + this.shuzi_daxie(b)
        }
      }
      return c
    },
    zengjia_yihang1 () {
      const num =
        Number(
          this.fenpei.tiaozheng_tianxie[
            this.fenpei.tiaozheng_tianxie.length - 2
          ].fangan_num
        ) + 1
      console.log(this.fenpei.tiaozheng_tianxie)
      const num_zi = this.shuzi_daxie(num)
      this.fenpei.tiaozheng_tianxie.pop()
      this.fenpei.tiaozheng_tianxie.push({
        fangan: '方案' + num_zi,
        fangan_num: num,
        shuzi: '',
        gang: '——',
        shuzi2: '',
        shuliang: '',
        kehu_liang: ''
      })
      this.fenpei.tiaozheng_tianxie.push({ fangan: '增加一行' })
    },
    // 奖励增加一行
    zengjia_yihang2 () {
      const num =
        Number(
          this.fenpei.jiangli_tianxie[this.fenpei.jiangli_tianxie.length - 2]
            .fangan_num
        ) + 1
      const num_zi = this.shuzi_daxie(num)
      this.fenpei.jiangli_tianxie.pop()
      this.fenpei.jiangli_tianxie.push({
        fangan: '方案' + num_zi,
        fangan_num: num,
        wancheng_lv1: '',
        zhi: '——',
        wancheng_lv2: '',
        shuliang: ''
      })
      this.fenpei.jiangli_tianxie.push({ fangan: '增加一行' })
    },
    tiaozheng (data) {
      if (Number(data.shuzi2) <= Number(data.shuzi)) {
        data.shuzi2 = Number(data.shuzi) + 1
      }
    },
    // 奖励数量里的第一个完成率小于第二个
    tiaozheng2 (data) {
      if (Number(data.wancheng_lv2) <= Number(data.wancheng_lv1)) {
        data.wancheng_lv2 = Number(data.wancheng_lv1) + 1
      }
    },
    kehu_liang_zhi () {
      const date = this.fenpei
      const data = this.fenpei.tiaozheng_tianxie
      for (let i = 0; i < data.length; i++) {
        if (data[i].shuliang != '' && date.jichu_num != '') {
          data[i].kehu_liang =
            Number(data[i].shuliang) + Number(date.jichu_num)
        }
      }
    },
    // 增加一列
    zengjia_yilie () {
      this.fenpei.kehu_fenpei_bili.push({
        // 表头
        name:
          '区间' +
          this.shuzi_daxie(Number(this.fenpei.kehu_fenpei_bili.length) + 1),
        con: 'qujian' + (Number(this.fenpei.kehu_fenpei_bili.length) + 1)
      })
      for (let i = 0; i < this.fenpei.kehu_fenpei_neirong.length; i++) {
        this.$set(
          this.fenpei.kehu_fenpei_neirong[i],
          'qujian' + Number(this.fenpei.kehu_fenpei_bili.length),
          ''
        )
      }
    },

    // 减少一列
    jianshao_yilie () {
      if (this.fenpei.kehu_fenpei_bili.length > 3) {
        this.fenpei.kehu_fenpei_bili.pop()
      }
      for (let i = 0; i < this.fenpei.kehu_fenpei_neirong.length; i++) {
        this.fenpei.kehu_fenpei_neirong[i][
          'qujian' + (this.fenpei.kehu_fenpei_bili.length + 1)
        ] = null
      }
    },

    // 计算客户分配比例合计
    kehu_bili_heji () {
      const date = this.fenpei
      const data = this.fenpei.kehu_fenpei_bili
      const datb = this.fenpei.kehu_fenpei_neirong
      console.log(data)
      console.log(datb)
      for (let i = 0; i < data.length; i++) {
        console.log(data[i])
        let zhi = ''
        for (let a = 1; a < datb.length - 1; a++) {
          if (datb[a][data[i].con].length != 0) {
            zhi = Number(zhi) + Number(datb[a][data[i].con])
          }
        }
        this.fenpei.kehu_fenpei_neirong[
          this.fenpei.kehu_fenpei_neirong.length - 1
        ][data[i].con] = zhi
        console.log(zhi)
        console.log(datb[datb.length - 1])
        console.log(data[i].con)
        console.log(datb[datb.length - 1][data[i].con])
      }
    },
    // 自动分配规则
    zidong_fenpei_guize () {
      console.log(this.fenpei)
      if (
        this.fenpei.fenpei_shunwei1 == this.fenpei.fenpei_shunwei2 ||
        this.fenpei.fenpei_shunwei1 == this.fenpei.fenpei_shunwei3
      ) {
        this.fenpei.fenpei_shunwei2 = ''
        this.fenpei.fenpei_shunwei3 = ''
      }
      const two_list = []
      for (let i = 0; i < this.shunwei_list.length; i++) {
        if (this.fenpei.fenpei_shunwei1 != this.shunwei_list[i].value) {
          two_list.push(this.shunwei_list[i])
        }
      }
      const three_list = []
      for (let i = 0; i < two_list.length; i++) {
        if (
          this.fenpei.fenpei_shunwei2 != two_list[i].value &&
          this.fenpei.fenpei_shunwei2.length != 0
        ) {
          three_list.push(two_list[i])
        }
      }
      if (three_list.length != 0) {
        this.fenpei.fenpei_shunwei3 = three_list[0].value
      }
      this.shunwei_list2 = two_list
      this.shunwei_list3 = three_list
    },

    panduan () {
      const i = this.fenpei
      const cuowu = []
      if (i.gonghai_chi_num == 1) {
        if (i.tuihui_yue_num == 0 && i.tuihui_yue.length == 0) {
          cuowu.push('自动退回1未填写')
        }
        if (i.tuihui_no_num == 0 && i.tuihui_no.length == 0) {
          cuowu.push('自动退回2未填写')
        }
        if (i.liangci_num == 0 && i.liaci_tian_tuihui.length == 0) {
          cuowu.push('自动退回3未填写')
        }

        if (i.tingliu_shichang.length == 0) {
          cuowu.push('停留时长过长未')
        }
        if (i.bei_tuihui_cishu.length == 0) {
          cuowu.push('被退回多少次未')
        }
        if (i.yuefen1.length == 0) {
          cuowu.push('天数1')
        }
        if (i.yuefen2.length == 0) {
          cuowu.push('天数2')
        }
        if (i.yuefen3.length == 0) {
          cuowu.push('天数3')
        }
        if (i.yuefen4.length == 0) {
          cuowu.push('天数4')
        }
        if (i.yuefen5.length == 0) {
          cuowu.push('天数5')
        }
      } else {
        if (i.jichu_num.length == 0) {
          cuowu.push('基础数量未填写')
        }
        if (i.fenpei_shunwei2.length == 0) {
          cuowu.push('分配顺位2未填写')
        }
        if (i.fenpei_shunwei3.length == 0) {
          cuowu.push('分配顺位3未填写')
        }

        if (i.jiangli_shuliang_num == 0) {
          for (let a = 0; a < i.jiangli_tianxie.length - 1; a++) {
            if (
              i.jiangli_tianxie[a].wancheng_lv1.length == 0 ||
              i.jiangli_tianxie[a].wancheng_lv2.length == 0 ||
              i.jiangli_tianxie[a].shuliang.length == 0
            ) {
              cuowu.push('奖励数量里面值未填写' + (a + 1))
            }
          }
        }
        if (i.kehu_fenpei_num == 0) {
          for (let a = 0; a < i.kehu_fenpei_bili.length; a++) {
            for (let b = 0; b < i.kehu_fenpei_neirong.length - 1; b++) {
              if (
                i.kehu_fenpei_neirong[b][i.kehu_fenpei_bili[a].con].length == 0
              ) {
                cuowu.push(
                  i.kehu_fenpei_bili[a].name +
                    i.kehu_fenpei_neirong[b].dengji +
                    '未填写'
                )
              }
            }
            if (
              i.kehu_fenpei_neirong[i.kehu_fenpei_neirong.length - 1][
                i.kehu_fenpei_bili[a].con
              ] != 100
            ) {
              cuowu.push(
                i.kehu_fenpei_bili[a].name +
                  i.kehu_fenpei_neirong[i.kehu_fenpei_neirong.length - 1]
                    .dengji +
                  '不够100%'
              )
            }
          }
        }
        if (
          (i.tuihui_zhudong_num == 0 && i.zhudong_tuihui_jia.length == 0) ||
          i.jianchi_kehu_shuliang.length == 0
        ) {
          cuowu.push('主动退回里面未填值')
        }
        if (i.tuihui_yue_num == 0 && i.tuihui_yue.length == 0) {
          cuowu.push('自动退回1未填写')
        }
        if (i.tuihui_no_num == 0 && i.tuihui_no.length == 0) {
          cuowu.push('自动退回2未填写')
        }
        if (i.liangci_num == 0 && i.liaci_tian_tuihui.length == 0) {
          cuowu.push('自动退回3未填写')
        }
        if (i.tingliu_shichang.length == 0) {
          cuowu.push('停留时长过长未')
        }
        if (i.bei_tuihui_cishu.length == 0) {
          cuowu.push('被退回多少次未')
        }
        if (i.yuefen1.length == 0) {
          cuowu.push('天数1')
        }
        if (i.yuefen2.length == 0) {
          cuowu.push('天数2')
        }
        if (i.yuefen3.length == 0) {
          cuowu.push('天数3')
        }
        if (i.yuefen4.length == 0) {
          cuowu.push('天数4')
        }
        if (i.yuefen5.length == 0) {
          cuowu.push('天数5')
        }
      }
      if (cuowu.length == 0) {
        return true
      } else {
        return false
      }
    },

    dianji_baocun () {
      if (this.panduan() === true) {
        const jiangli_list = []
        const fenpei_bili_list = []
        const i = this.fenpei
        const obj2 = {
          kehu_fenpei_neirong: i.kehu_fenpei_neirong,
          kehu_fenpei_bili: i.kehu_fenpei_bili
        }
        const str = JSON.stringify(obj2)

        for (let a = 0; a < i.jiangli_tianxie.length - 1; a++) {
          jiangli_list.push({
            name: i.jiangli_tianxie[a].fangan,
            rate_start: i.jiangli_tianxie[a].wancheng_lv1 + '',
            rate_end: i.jiangli_tianxie[a].wancheng_lv2 + '',
            qty_reward: i.jiangli_tianxie[a].shuliang + '',
            id2: i.jiangli_tianxie[a].fangan_num + ''
          })
        }
        for (let a = 0; a < i.kehu_fenpei_bili.length; a++) {
          const bili_obj = {}
          if (a == 0) {
            bili_obj.rate_start = '0'
          } else {
            bili_obj.rate_start =
              Number(i.kehu_fenpei_neirong[0][i.kehu_fenpei_bili[a - 1].con]) +
              ''
          }
          bili_obj.rate_end =
            i.kehu_fenpei_neirong[0][i.kehu_fenpei_bili[a].con] + ''
          for (let b = 1; b < i.kehu_fenpei_neirong.length - 1; b++) {
            bili_obj['level' + b] =
              i.kehu_fenpei_neirong[b][i.kehu_fenpei_bili[a].con]
          }
          fenpei_bili_list.push(bili_obj)
        }
        console.log(fenpei_bili_list)
        const obj = {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          // tuihui_zidong,tuihui_zhudong_num,zhudong_tuihui_jia,jianchi_kehu_shuliang
          flag_visible: i.gonghai_chi_num + '', // 公海池是否可视
          init_qty_orig: Number(this.init_qty_orig) + '',
          init_qty: i.jichu_num.length != 0 ? i.jichu_num : null, // 基础数量
          flag_cust_allot_rate: i.kehu_fenpei_num == 1 ? '0' : '1', // 客户分配比例  1 0
          allot_sort:
            i.fenpei_shunwei1 +
            ',' +
            i.fenpei_shunwei2 +
            ',' +
            i.fenpei_shunwei3, // 分配顺位  1   2   3
          spec_rule_1: i.yueding_1 == 1 ? '0' : '1', // 特别约定    1  0
          spec_rule_2: i.yueding_2 == 1 ? '0' : '1', // 1 0
          spec_rule_3: i.yueding_3 == 1 ? '0' : '1', // 1 0
          spec_rule_4: i.yueding_4 == 1 ? '0' : '1', // 1 0
          flag_spec_allot: i.teshu_zhipai_num == 1 ? '0' : '1', // 特殊指派  1 0
          supply_type: i.buchong_num == 1 ? '2' : '1', // 成交后客户补充方式  1    2
          flag_reward_qty: i.jiangli_shuliang_num == 1 ? '0' : '1', // 奖励数量   1 0
          reward_qty_type: Number(i.jiangli_num) + 1 + '', // 奖励参考的指标类型  1  2 3
          auto_back_rule_1: i.tuihui_yue.length != 0 ? i.tuihui_yue : null, // 自动退回规则
          flag_auto_back_rule_1: i.tuihui_yue_num == 1 ? '0' : '1', // 1   0
          auto_back_rule_2: i.tuihui_no.length != 0 ? i.tuihui_no : null, // 自动退回规则2
          flag_auto_back_rule_2: i.tuihui_no_num == 1 ? '0' : '1', // 1 0
          flag_auto_back_rule_3: i.liangci_num == 1 ? '0' : '1', // 1 0
          auto_back_rule_3: i.liaci_tian_tuihui, // 两次拜访间隔时间超过x天自动退回
          auto_back_area: Number(i.tuihui_zidong_num) + 1 + '', // 1公海池  2部门主管
          manual_back_flag: i.tuihui_zhudong_num == 1 ? '0' : '1', // 主动退回  1启用  0不启用
          manual_back_cust_num:
            i.zhudong_tuihui_jia.length != 0 ? i.zhudong_tuihui_jia : null, // 累计主动退回客户家数
          manual_back_subtract_qty:
            i.jianchi_kehu_shuliang.length != 0
              ? i.jianchi_kehu_shuliang
              : null, // 减持总客户数量
          cold_rule_1_month: i.tingliu_shichang, // 公海池停留时长    冷冻仓
          cold_rule_2_num: i.bei_tuihui_cishu, // 客户被主动退回   冷冻仓
          stay_time_green: i.yuefen1 + ',' + i.yuefen2, // 逗号隔开
          stay_time_yellow: i.yuefen3 + ',' + i.yuefen4, //
          stay_time_red: i.yuefen5, //
          list_rate: fenpei_bili_list.length == 0 ? null : fenpei_bili_list,
          // list_update:tiaozheng_list.length==0?null:tiaozheng_list,   先隐藏掉
          list_reward: jiangli_list.length == 0 ? null : jiangli_list,
          cust_allot_rate_str: i.kehu_fenpei_neirong.length == 0 ? null : str
        }
        set_customer_allot_rule({ data: obj }).then((res) => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {
          }
        })
      } else {
        // alert(this.panduan())
        this.$message({
          message: '请将规则设置完整后保存',
          type: 'warning'
        })
      }
    },
    // 客户分配比例   后一个值比前面大  失去焦点
    bili_qujian_num_dayu (num, i, index) {
      if (Number(i[index]) <= Number(num)) {
        i[index] = Number(num) + 1
      }
    },
    // 表头颜色
    biaotou (row) {
      // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    chongxin_huoqu () {
      this.huoqu = true
      const isNotBlank = (value) => {
        if (
          value === null ||
          value === undefined ||
          value !== value ||
          value === 'null' ||
          value === 'undefined' ||
          value === 'NaN' ||
          value === ''
        ) {
          return false
        } else {
          let jsonStr = null
          try {
            jsonStr = JSON.stringify(value)
          } catch (error) {
            jsonStr = ''
          }
          if (jsonStr === '{}') {
            return false
          }
          return true
        }
      }
      Array.prototype.equals = function (array, key, order) {
        // array--要比较的数组（必需）
        // key--如果数组里边包裹着对象，则可以比较对象的某个键值对（可选）
        // order--数组中的顺序是否可以打乱（可选）
        if (!array instanceof Array) return false
        if (this.length != array.length) return false
        if (order) {
          // 顺序要求一致
          for (let i = 0; i < this.length; i++) {
            if (isNotBlank(key)) {
              if (this[i][key] !== array[i][key]) {
                return false
                break
              }
            } else {
              if (this[i] !== array[i]) {
                return false
                break
              }
            }
          }
        } else {
          // 顺序可以不一致
          for (let i = 0; i < this.length; i++) {
            let log = false
            for (let j = 0; j < array.length; j++) {
              if (isNotBlank(key)) {
                if (this[i][key] === array[j][key]) {
                  log = true
                  break
                }
              } else {
                if (this[i] === array[j]) {
                  log = true
                  break
                }
              }
            }
            if (!log) {
              return false
              break
            }
          }
        }
        return true
      }
      Object.defineProperty(Array.prototype, 'equals', { enumerable: false })
      query_cust_level_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          this.huoqu = false
          const list = JSON.parse(res.data.body.data)
          console.log(list)
          console.log(this.fenpei.kehu_fenpei_neirong)
          const old = []
          for (let i = 1; i < this.fenpei.kehu_fenpei_neirong.length - 1; i++) {
            this.$set(
              this.fenpei.kehu_fenpei_neirong[i],
              'text',
              this.fenpei.kehu_fenpei_neirong[i].dengji
            )
            old.push(this.fenpei.kehu_fenpei_neirong[i])
          }

          if (old.equals(list, 'text', false) == false) {
            const a = this.fenpei.kehu_fenpei_neirong
            a.splice(1, a.length)
            list.map((item, index) => {
              a.push({
                dengji: item.text,
                qujian1: '',
                qujian2: '',
                qujian3: '',
                qujian4: ''
              })
            })
            a.push({
              dengji: '合计',
              qujian1: '',
              qujian2: '',
              qujian3: '',
              qujian4: ''
            })
          }
        } else if (res.data.code == 500) {
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./quanxian.scss";
</style>
